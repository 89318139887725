.pool {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pool-container {
  width: 80%;
}

.body {
  width: 70%;
}
.pool-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    91.76deg,
    rgba(255, 227, 202, 0.6) 25.72%,
    rgba(255, 186, 123, 0.6) 110.26%
  );
  border-radius: 6px;
  padding: 10px 20px;
  height: 60px;
}

.left-header {
  display: flex;
  align-items: center;
}
.avatar {
  height: 50px;
  width: 50px;
  background-color: #f97f41;
  border-radius: 50%;
  margin-right: 20px;
}

.left-header h3 {
  margin-top: 20px;
}

.left-header p {
  margin-top: -10px;
}

.pool-header .rate {
  font-weight: bold;
}
.pool-main {
  display: flex;
  justify-content: space-between;
}
.box {
  box-sizing: border-box;
  width: 467px;
  min-height: 234px;
  margin-top: 20px;
  background: linear-gradient(
    91.47deg,
    rgba(255, 255, 255, 0.6) 26.17%,
    rgba(255, 255, 255, 0.6) 51.25%
  );
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
}

.btns {
  display: flex;
}
.btns button {
  width: 100%;
}

.deposit {
  border-radius: 0px 0px 0px 6px;
  border-right: 1px solid #ccc;
}
.withdraw {
  border-radius: 0px 0px 6px 0px;
}

.fee {
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
}

.confirm {
  background-color: #f97f41;
}
