.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: #22242b;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.logo {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.rfc {
  color: #f97f41;
}
button {
  background-color: #f97f41;
  border: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
